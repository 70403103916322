<template>
  <div class="load-more" @click="loadMore">
    <van-divider class="divider"
      ><van-loading v-show="isLoading" size="0.9rem" text-size="0.9rem"
        >云加载中</van-loading
      >
      <span v-show="hasMore && !isLoading">点击加载更多</span>
      <span v-show="!hasMore && !isLoading">{{ text }}</span></van-divider
    >
  </div>
</template>

<script>
import { Divider, Loading } from "vant";
export default {
  name: "appLoadMore",
  props: {
    text: {
      type: String,
      default() {
        return "已经到底啦";
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasMore: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    vanLoading: Loading,
    [Divider.name]: Divider,
  },
  data: () => ({
    timer: null,
  }),
  methods: {
    loadMore() {
      clearTimeout(this.timer); //防抖
      this.timer = setTimeout(() => {
        this.$emit("loadMore");
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  width: 100%;
  height: 5.5rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem !important;
  .divider {
    width: 80%;
    height: 3rem;
    color: #858585;
    border-color: #d7d7d7;
    font-size: 0.9rem !important;
  }
}
</style>
