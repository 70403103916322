<template>
  <div
    id="class"
    :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
      isApp ? systemBarHeight : 0
    }px`"
    v-scroll:#class-detail-scroll="onScroll"
  >
    <class-table-app-header
      ref="classAppHead"
      @changeTab="changeTab"
      :isLoading="isLoading || classDetailLoading"
      :key="navKey"
    >
    </class-table-app-header>
    <div
      id="class-detail-scroll"
      class="class-detail-scroll show-scroll"
      :style="`height:${
        isApp ? screenHeight - systemBarHeight - 56 : screenHeight - 56
      }px;`"
    >
      <div class="class-content">
        <div class="head mt-10 mb-5">
          <div>
            <div class="head-title">{{ classDetail.name }}</div>
            <div
              class="d-flex align-center mt-1 tag-author"
              :style="'color: ' + classDetail.theme.detailBtnColor + '99'"
            >
              <span class="mr-2" v-show="classDetail.author">PublishedBy:</span>
              {{ classDetail.author }}
            </div>
          </div>
        </div>

        <v-tabs-items @change="tabChange" v-model="tab">
          <v-tab-item>
            <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh">
              <div
                class="card-content"
                :style="`min-height: ${
                  screenHeight - 180 - (isApp ? systemBarHeight : 0)
                }px`"
              >
                <div
                  v-if="classDetail.price"
                  class="d-flex mt-1 justify-space-between"
                >
                  <div class="d-flex align-center tag">
                    <v-icon class="mr-2" small>mdi-tag</v-icon
                    >{{ classDetail.tag }}
                  </div>
                  <div
                    :style="`color: ${
                      classDetail.price === 0 ? 'red' : '#909090'
                    }`"
                  >
                    {{
                      classDetail.price === 0
                        ? "免费"
                        : "￥ " + classDetail.price
                    }}
                  </div>
                </div>
                <div v-show="classDetail.rate" class="desc mt-2">
                  <div class="text-details-author">
                    <div plain rounded class="text-details-value hide-scroll">
                      {{ classDetail.rate }}
                      <v-icon small color="warning" style="opacity: 0.8">
                        mdi-heart
                      </v-icon>
                    </div>
                    <div class="text-details-desc">用户评分</div>
                  </div>
                  <div class="mid"></div>
                  <div class="text-details-author">
                    <div plain rounded class="text-details-value hide-scroll">
                      {{ classDetail.subscribeCount
                      }}<v-icon
                        small
                        :color="classDetail.theme.detailIconColor + 'dd'"
                        >mdi-bell</v-icon
                      >
                    </div>
                    <div class="text-details-desc">订阅人数</div>
                  </div>
                  <div class="mid"></div>
                  <div class="text-details-author">
                    <div plain rounded class="text-details-value hide-scroll">
                      {{ classDetail.passageCount
                      }}<v-icon
                        small
                        :color="classDetail.theme.detailIconColor + 'dd'"
                      >
                        mdi-book-variant</v-icon
                      >
                    </div>
                    <div class="text-details-desc">当前课时</div>
                  </div>
                </div>
                <div
                  v-show="classDetail.imgList"
                  class="head-ppt none-select mt-3 mb-5 py-0"
                >
                  <v-carousel
                    class="rounded-lg none-select"
                    cycle
                    height="9rem"
                    show-arrows-on-hover
                    hide-delimiter-background
                    delimiter-icon="mdi-minus"
                    prev-icon="mdi-chevron-left"
                    next-icon="mdi-chevron-right"
                  >
                    <v-carousel-item
                      v-for="(item, index) in classDetail.imgList"
                      :key="index"
                      :src="item"
                    >
                    </v-carousel-item>
                  </v-carousel>
                </div>
                <div v-show="classDetail.fullDes" class="details my-9">
                  <div class="detail-title my-4">关于本课程</div>
                  <div class="detail-text" v-html="classDetail.fullDes"></div>
                </div>
              </div>
            </van-pull-refresh>
          </v-tab-item>
          <v-tab-item>
            <van-pull-refresh
              style="min-height: 100%"
              v-model="isRefreshing"
              @refresh="onRefresh"
            >
              <div
                class="card-content"
                :style="`min-height: ${
                  screenHeight - 180 - (isApp ? systemBarHeight : 0)
                }px`"
              >
                <v-fade-transition>
                  <error-page
                    :offLine="offLine"
                    @onRefresh="onRefresh"
                    v-if="isEmpty(classList) && !isLoading"
                  ></error-page>
                </v-fade-transition>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    class="other-class mt-2 mb-7"
                    v-for="(item, index) in classList"
                    :key="item.id"
                  >
                    <v-lazy
                      v-model="item.isActive"
                      :options="{
                        threshold: 0.5,
                      }"
                      min-height="200"
                      transition="fade-transition"
                    >
                      <div>
                        <div class="d-flex justify-space-between">
                          <div class="d-flex align-center mark left-mark">
                            <v-icon style="line-height: 1" small>
                              mdi-clock
                            </v-icon>
                            <span class="ml-1">{{ item.time }}</span>
                          </div>
                          <div class="d-flex align-center mt-1 mark right-mark">
                            <v-icon style="line-height: 1" small>
                              mdi-tag
                            </v-icon>
                            <span class="ml-1">{{ item.tag }}</span>
                          </div>
                        </div>
                        <v-card
                          @click="goPassagePage(item.id)"
                          v-ripple
                          class="rounded-lg"
                          elevation="0"
                          :style="`background-image: linear-gradient(to right top,${item.theme.bgColor}00,${item.theme.bgColor}00,${item.theme.bgColor}09,${item.theme.bgColor}66);`"
                        >
                          <div
                            class="d-flex flex-no-wrap justify-space-between align-center"
                          >
                            <div
                              class="ml-4 mr-1"
                              style="max-height: 4rem; overflow: hidden"
                            >
                              <div class="card-title" v-text="item.title"></div>
                            </div>
                            <v-avatar class="ma-3 rounded" size="70" tile>
                              <v-img :src="item.img">
                                <template v-slot:placeholder>
                                  <v-row
                                    :style="`background-color:${item.theme.bgColor}dd`"
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>
                          </div>
                          <div
                            class="d-flex justify-space-between align-center"
                            :style="`color: ${item.theme.btnFontColor}aa;`"
                          >
                            <v-card-subtitle
                              class="mt-0 pt-0 pb-0 cut-words"
                              :style="`color: ${item.theme.btnFontColor}aa`"
                              >{{ item.subTitle }}
                              <div class="d-flex align-center pr-3 left-desc">
                                <div class="d-flex align-center">
                                  <span>难度</span>
                                  <v-rating
                                    class="rate"
                                    background-color="grey lighten-2"
                                    color="warning"
                                    hover
                                    length="5"
                                    readonly
                                    x-small
                                    size="1"
                                    dense
                                    :value="classList[0].rate"
                                  ></v-rating>
                                </div>
                                <div class="mx-1">/</div>
                                <div>{{ classList[0].learnCount }} 人已学</div>
                              </div></v-card-subtitle
                            >
                            <v-btn
                              :style="`color: ${item.theme.btnFontColor}`"
                              class="mr-1 btn-disabled"
                              x-small
                              dense
                              text
                            >
                              <v-icon small dense>mdi-play</v-icon>
                            </v-btn>
                          </div>
                        </v-card>
                        <v-divider
                          v-show="index < classList.length - 2"
                          style="opacity: 0.5"
                          class="mb-1 mt-9"
                        ></v-divider>
                      </div>
                    </v-lazy>
                  </v-col>
                </v-row>
              </div>
              <app-load-more
                class="mt-5"
                v-show="!isEmpty(this.classList)"
                :isLoading="isLoading"
                :hasMore="hasNext"
                @loadMore="loadData(true)"
              ></app-load-more>
              <div class="footer-takeplace"></div>
            </van-pull-refresh>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <v-slide-x-transition>
      <v-toolbar
        height="70px"
        width="100%"
        v-show="tab === 0"
        style="position: fixed; bottom: 0; left: 0"
      >
        <div
          class="d-flex align-center justify-center rounded-lg overflow-hidden"
          style="width: 100%; height: 100%"
        >
          <v-fade-transition>
            <v-btn
              v-show="!classDetail.subscribed"
              :color="classDetail.theme.detailBtnColor"
              dark
              block
              class="rounded-lg my-auto"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>订阅课程
            </v-btn>
          </v-fade-transition>
          <v-fade-transition>
            <v-btn
              v-show="classDetail.subscribed"
              :color="classDetail.theme.detailBtnColor + '50'"
              dark
              block
            >
              <v-icon class="mr-2">mdi-minus</v-icon>退订课程
            </v-btn>
          </v-fade-transition>
        </div>
      </v-toolbar>
    </v-slide-x-transition>
    <v-fab-transition>
      <v-btn
        style="bottom: 90px; right: 2rem"
        :key="$route.path"
        fab
        bottom
        right
        fixed
        small
        @click="goTop"
        v-show="scrollTop > 80"
      >
        <v-icon color="#909090">mdi-format-vertical-align-top</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
import $api from "@/api/api";
import ClassTableAppHeader from "@/views/classDetail/components/classDetailAppHeader";
import AppLoadMore from "@/components/app/appLoadMore";

export default {
  name: "classTableIndex",
  mixins: [setSystemColor, buttonVibrate],
  components: {
    ErrorPage: () => import("@/views/classDetail/components/errorPage"),
    ClassTableAppHeader,
    AppLoadMore,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
    }),
  },
  data: () => ({
    tab: 0,
    navKey: 0,
    classId: null,
    offLine: false,
    classDetailLoading: false,
    isLoading: false,
    isRefreshing: false,
    firstCreat: true,
    totalPages: 0,
    hasNext: false,
    isLoadingMore: false,
    currentPage: 1,
    lastScrollTop: 0,
    scrollTop: 0,
    classDetail: {
      theme: {
        topTextColor: "#ccccff",
        bottomTextColor: "#808080",
        bgColor: "#7873c0",
        detailIconColor: "#4c4172",
        detailBtnColor: "#7873c0",
      },
    },
    classList: [],
  }),
  methods: {
    isEmpty,
    tabChange() {
      this.$refs.classAppHead.tab = this.tab;
    },
    changeTab(tab) {
      this.tab = tab;
      this.autoLoad();
    },
    goPassagePage(id) {
      this.zzzShort();
      this.$router
        .push({
          path: "/reader",
          query: {
            id: id,
          },
        })
        .catch((err) => err);
    },
    goTop() {
      this.zzzShort();
      this.$vuetify.goTo(0, {
        container: "#class-detail-scroll",
        duration: 800,
        easing: "easeInOutCubic",
      });
    },
    //页面滚动判断方法
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        //scrollTop被卷去的高度 ；offsetHeight可视区域高度；scrollHeight整个元素的总高度包括不可见区域
        let scrollHeight = e.target.scrollHeight;
        let scrollTop = e.target.scrollTop;
        let offsetHeight = e.target.offsetHeight;
        if (
          scrollHeight - offsetHeight - scrollTop < 150 &&
          !this.isLoading &&
          !this.isLoadingMore
        ) {
          console.log("loadmore...");
          if (this.hasNext) {
            this.isLoadingMore = true;
            this.loadData(true);
          }
        }
        if (scrollTop > 20) {
          this.$refs.classAppHead.title = this.classDetail.name;
        } else {
          this.$refs.classAppHead.title = "课程详情";
        }
        this.scrollTop = e.target.scrollTop;
      }, 50);
    },
    onRefresh() {
      // 调接口
      this.classList = [];
      if (this.tab === 0) {
        this.classDetail = {
          theme: {
            lightTextColor: "#ccccff",
            bgColor: "#7873c0",
            darkTextColor: "#808080",
          },
        };
        this.loadClassDetail();
      }
      if (this.tab === 1) {
        this.loadData();
      }
    },
    autoLoad() {
      if (this.tab === 0 && !this.classDetail.name) {
        this.loadClassDetail();
      }
      if (this.tab === 1 && isEmpty(this.classList)) {
        this.loadData();
      }
    },
    loadClassDetail() {
      this.classDetailLoading = true;
      let getData = {
        id: this.classId,
      };
      $api
        .getClassDetail(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.classDetail = res.data;
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isRefreshing = false;
          this.classDetailLoading = false;
          this.$forceUpdate();
        });
    },
    loadData(loadMore) {
      this.offLine = false;
      this.isLoading = true;
      if (loadMore) {
        if (!this.hasNext) {
          this.isLoading = false;
          return this.$toast("已经加载到最后啦!");
        }
      } else {
        // 全新加载
        this.classList = [];
        this.currentPage = 1;
        this.isLoadingMore = false;
        this.totalPages = 0;
        this.hasNext = false;
      }
      let getData = {
        id: this.classId,
        limit: 20,
        page: this.currentPage,
      };
      $api
        .getPassageList(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.currentPage += 1;
            let result = res.data.result;
            this.classList = this.classList.concat(result);
            this.totalPages = res.data.totalPages;
            this.hasNext = res.data.hasNext;
            // this.
            if (this.firstCreat) {
              if (this.classDetail.subscribed) {
                this.tab = 1;
              }
              this.$refs.classAppHead.tab = this.tab;
            }
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          this.offLine = true;
          console.log(err);
        })
        .finally(() => {
          this.$forceUpdate();
          this.isLoading = false;
          this.isLoadingMore = false;
          this.isRefreshing = false;
        });
    },
    init() {
      const query = this.$route.query;
      this.classId = query.id;
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#ffffff");
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
      // 调接口
    },
  },
  created() {
    console.info("classDetail组件被创建");
    this.init();
    this.autoLoad();
  },
  mounted() {
    console.info("classDetail组件被挂载");
  },
  activated() {
    console.info("classDetail组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
      if (this.needRefresh) {
        console.info("refreshing...");
        document.getElementById("class-detail-scroll").scrollTo(0, 0);
        this.needRefresh = false;
        this.tab = 0;
        this.$refs.classAppHead.tab = 0;
        this.onRefresh();
      } else {
        this.$vuetify.goTo(this.lastScrollTop, {
          container: "#class-detail-scroll",
          duration: 800,
          easing: "easeInOutCubic",
        });
      }
    }
  },
  deactivated() {
    this.lastScrollTop = document.querySelector(
      "#class-detail-scroll"
    ).scrollTop;
    console.info("classDetail组件被暂停");
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (from.name === "classDetail") {
        return;
      }
      if (from.name !== "Reader") {
        this.needRefresh = true;
      } else {
        this.needRefresh = false;
      }
      console.log("needRefresh：", this.needRefresh);
    },
  },
};
</script>

<style lang="scss" scoped>
#class {
  height: 100%;
  overflow: hidden;
  .class-detail-scroll {
    overflow-y: auto;

    .class-content {
      width: calc(100% - 1rem);
      margin: 0 auto;
      min-height: 82vh;
      padding: 0;
      word-wrap: break-word;

      @media (min-width: 960px) {
        width: 70%;
        margin: 0 auto; // 剧中
        max-width: 900px !important;
      }
      @media (min-width: 1264px) {
        max-width: 1085px !important;
      }
      @media (min-width: 1904px) {
        max-width: 1185px !important;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .head-title {
          letter-spacing: 0.2rem;
          font-size: 1.2rem;
          font-weight: 700;
          transform: scaleY(1.2);
          --webkit-transform: scaleY(1.2);
        }
        .tag {
          font-size: 1rem;
          color: #808080;
          font-weight: 300;
        }
        .tag-author {
          font-size: 0.9rem;
          color: #9fa8da;
          //font-weight: 300;
        }
      }
      .desc {
        padding: 0;
        height: 5rem;
        //background-color: #8ca0ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mid {
          display: inline-block;
          height: 20px;
          width: 1.5px;
          margin: 0 10px;
          vertical-align: middle;
          background-color: #b7b7b7;
        }

        .text-details-author {
          //flex-basis: 10rem;
          text-align: center;

          .text-details-value {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: dosisbold, sans-serif;
            font-size: 1.1rem;
            font-weight: 700;
            color: #aaaaaa;
            letter-spacing: 0.2rem;
            margin: 0 0 0.4rem 0;
            //max-width: 4.2rem;
            height: 2rem;
            white-space: nowrap;
            //overflow-x: scroll;

            ::v-deep .v-btn__content {
              opacity: 1;
            }
          }

          .text-details-desc {
            font-size: 0.8rem;
            font-weight: 500;
            letter-spacing: 0.05rem;
            line-height: 0.8rem;
            margin-top: -0.2rem;

            > i {
              margin: 0 0.2rem;
              font-size: 0.8rem;
              line-height: 0.9rem;
            }
          }
        }
      }

      .head,
      .card-content {
        padding: 0 0.9rem;
        break-inside: avoid;
      }

      .head-ppt {
        overflow: hidden;
        ::v-deep .v-btn__content {
          .v-icon {
            font-size: 1rem !important;
          }
        }
      }

      .details {
        .detail-title {
          font-size: 1.2rem;
          font-weight: 700;
          color: #505050;
        }
        .detail-text {
          letter-spacing: 0.2rem;
          font-size: 0.86rem;
          font-weight: 500;
          text-align: justify;
          color: #707070;
        }
      }

      .other-class {
        break-inside: avoid;
        .rate {
          --webkit-transform: scale(0.9);
          transform: scale(0.9);
          transform-origin: top;
        }
        .card-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 1rem;
          line-height: 1.3;
          font-weight: 700;
        }
        .left-desc {
          font-size: 0.9rem;
          vertical-align: middle;
          -webkit-transform: scale(0.85);
          transform: scale(0.85);
          transform-origin: left;
        }
        .mark {
          -webkit-transform: scale(0.8);
          transform: scale(0.8);
        }
        .left-mark {
          transform-origin: left;
        }
        .right-mark {
          transform-origin: right;
        }
      }

      .footer-takeplace {
        width: 100%;
        height: 7.5rem;
      }
    }
  }
}
</style>
